<template>
    <div class="profile-info">
        <ui-card-skeleton v-if="loading" :rows="1" />
        <template v-else>
            <ui-card>
                <div class="profile-info__row">
                    <div class="profile-info__left-column">
                        <h5>
                            Profile {{ profile.name }}
                        </h5>
                        <ui-list>
                            <ui-list-item title="Role:">
                                <template #text>
                                    {{ profile.role }}
                                </template>
                            </ui-list-item>
                            <ui-list-item title="Created at:">
                                <template #text>
                                    {{ $dayjs(profile.created_at).format('LL, LT') }}
                                </template>
                            </ui-list-item>
                            <ui-list-item title="Updated at:">
                                <template #text>
                                    {{ $dayjs(profile.updated_at).format('LL, LT') }}
                                </template>
                            </ui-list-item>
                        </ui-list>
                    </div>
                    <div class="profile-info__right-column">
                        <h5>
                            Available scopes
                        </h5>
                        <ui-list-group bordered>
                            <div v-if="profile.scopes?.length === 0">
                                <empty-state title="No scopes found..." class="m-4" />
                            </div>
                            <div v-else>
                                <ui-list-group-item v-for="scope in profile.scopes?.slice(0, showMoreScopes[profile.id] ? profile.scopes.length : 3)" :key="scope.id">
                                    <div>
                                        <h6>
                                            {{ scope.id }}
                                        </h6>
                                    </div>
                                    <div v-for="permission in scope.permissions">
                                        <div class="scopes-item w-100 d-flex align-items-center justify-content-between">
                                            <h6 class="scopes-item__title">
                                                {{ permission.name }}
                                            </h6>
                                            <ui-switch :id="`${permission.name}-active`" :name="`${permission.name}-active`" :disabled="true" v-model:value="active" />
                                        </div>
                                    </div>
                                </ui-list-group-item>
                                <ui-list-group-item  v-if="profile.scopes?.length > 3" class="profile-info__show-more-button" @click="toggleMoreScopes(profile.id)">
                                    <i class="fas" :class="showMoreScopes[profile.id] ? 'fa-minus-circle' : 'fa-plus-circle'"></i> 
                                    Show {{ showMoreScopes[profile.id] ? 'less' : 'more' }}
                                </ui-list-group-item>
                            </div>
                        </ui-list-group>
                    </div>
                </div>
            </ui-card>
        </template>
    </div>
</template>

<script>
import UiCardSkeleton from '@/components/ui/skeleton/CardSkeleton.vue';
import UiCard from '@/components/ui/Card.vue';
import UiList from '@/components/ui/list/List.vue';
import UiListItem from '@/components/ui/list/ListItem.vue';
import UiListGroup from '@/components/ui/list-group/ListGroup.vue';
import UiStatus from '@/components/ui/BadgeStatus';
import UiListGroupItem from '@/components/ui/list-group/ListGroupItem';
import UiSwitch from '@/components/ui/Switch.vue';
import EmptyState from '@/components/ui/EmptyState.vue';

export default {
    components: {
        UiCard,
        UiCardSkeleton,
        UiList,
        UiListItem,
        UiListGroup,
        UiStatus,
        UiListGroupItem,
        UiSwitch,
        EmptyState,
    },
    props: {
        profile: {
            type: Object,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showMoreScopes: {},
            active: true,
        };
    },
    methods: {
        toggleMoreScopes(scopes_id) {
            if (this.showMoreScopes[scopes_id]) {
                delete this.showMoreScopes[scopes_id];
                return;
            }

            this.showMoreScopes[scopes_id] = true;
        }
    },
};
</script>

<style lang="scss">
.profile-info {
    &__row {
        display: flex;
        flex-direction: column;
        gap: 16px;
        
        @media(min-width: 1200px) {
            flex-direction: initial;
        }
    }

    &__left-column {
        max-width: 100%;
        width: 100%;
        flex-shrink: 0;
        
        @media(min-width: 1200px) {
            width: 350px;
        }
    }

    &__right-column {
        width: 100%;
    }

    &__method-container {
        display: flex;
        gap: 16px;
        width: 100%;
    }

    &__flag {
        border-radius: 6px;
        box-shadow: 0 2px 4px rgba(#000000, 0.15);
        height: 24px;
        margin-right: 8px;
        width: 24px;
    }

    &__strong {
        color: $general-black;
        font-weight: 600;
    }

    &__method-title {
        color: $ecart-primary;
        font-weight: 500;
        line-height: 1;
        margin-bottom: 0;
        margin-right: 10px;
        width: 90px;
    }

    &__method-text {
        font-weight: 500;
        line-height: 1;
        margin-bottom: 0;
        text-transform: capitalize;
    }

    &__show-more-button {
        color: $ecart-primary;
        cursor: pointer;
        font-weight: 600;
        padding-bottom: 15px;
        padding-top: 15px;
        text-align: center;
    }

    &__scope-item {
        flex-direction: column;

    }
}
</style>
