<template>
    <div class="profiles-info">
        <b-button class="mb-3" variant="light" @click="$router.back()">
            <i class="fas fa-chevron-left"></i> Back
        </b-button>
        <div class="row">
            <scopes-info-list v-if="profile" :profile="profile" :loading="loading" />
        </div>
    </div>
</template>

<script>
import ScopesInfoList from '@/components/profiles/ScopesInfoList.vue';

export default {
    components: { ScopesInfoList },
    data() {
        return {
            profile_id: this.$route.query.id,
            loading: false,
            profile: {},
        }
    },
    mounted() {
        this.getProfile();    
    },
    methods: {
        async getProfile() {
            try {
                this.loading = true;
                const { data } = await this.$axios.get(`/_/profiles/${this.profile_id}`);
                this.profile = data;
                this.profile.scopes = this.groupScopes(data.scopes);
            } catch (error) {
                this.$toast.error('Failed to get profile');
            } finally {
                this.loading = false;
            }
        },
        groupScopes(scopes) {
            let arr = {};
            scopes.forEach(scope => {
                let obj = this.formatScopes(scope);
                if (arr[obj.id]) {
                    arr[obj.id].permissions.push(obj.permissions[0]);
                } else {
                    arr[obj.id] = {
                        id: obj.id,
                        permissions: [obj.permissions[0]]
                    };
                }
            });
            let group_scopes = Object.values(arr);
            return group_scopes;
        },
        formatScopes(scope) {
            let obj = {
                id: scope.split('_')[1].charAt(0).toUpperCase() + scope.split('_')[1].slice(1),
                permissions: [
                    {
                        key: scope,
                        name: scope.split('_')[0].charAt(0).toUpperCase() + scope.split('_')[0].slice(1)
                    }
                ]
            };
            return obj;
        }
    },
}
</script>